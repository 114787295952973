import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CheckboxStyleType } from 'ui/atoms/checkbox/Checkbox.types';

export const useStyles = makeStyles<Theme, CheckboxStyleType>(
  ({ spacing, palette: { gray, primary, white, error } }) => ({
    checkbox: {
      '& .MuiFormHelperText-root': {
        display: 'flex',
        alignItems: 'center',
        '& span': {
          display: 'inline-flex',
        },
      },
    },
    materialCheckbox: {
      padding: 0,
      color: gray.main,
      '& .MuiIconButton-label': {
        width: spacing(2.25),
        height: spacing(2.25),
      },
    },
    container: {
      display: 'flex',
    },
    checked: { color: primary.main },
    icon: {
      '&  > path:first-child': {
        fill: white.main,
      },
    },
    text: {
      marginLeft: spacing(2),
    },
    label: {
      '& .MuiFormControlLabel-label': {
        userSelect: 'none',
        paddingLeft: spacing(1),
        '&::after': {
          content: ({ required }: CheckboxStyleType) => (required ? '"*"' : 'none'),
          color: primary.main,
          paddingLeft: spacing(0.5),
        },
      },
      '&.MuiFormControlLabel-root': {
        marginLeft: 0,
      },
    },
    error: {
      '& .MuiFormControlLabel-label, span': {
        color: error.main,
        '&::after': {
          color: error.main,
        },
      },
    },
  }),
);
