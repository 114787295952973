import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type UseStylesProps = {
  bold?: boolean;
};

export const useStyles = makeStyles<Theme, UseStylesProps>(
  ({
    spacing,
    typography: {
      fontWeightBold,
      fontWeightRegular,
      h1: { fontFamily },
    },
  }) => ({
    hero: {
      fontSize: spacing(10),
      lineHeight: spacing(15),
    },
    header: {
      fontFamily: fontFamily,
      fontWeight: ({ bold }) => (bold ? fontWeightBold : fontWeightRegular),
    },
  }),
);
